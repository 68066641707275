import React from "react";
import logo from "../../assets/Jemmity_logo_230723.png";

export const HomePage = () => {
  return (
    <div
      className="d-flex align-items-center text-center vh-100"
      style={{ paddingTop: "200px" }}
    >
      <div className="container h-100 pb-5 d-flex flex-column justify-content-between">
        <div>
          <img src={logo} alt="JEMMITY" style={{ width: "60%" }} />
          <h1
            style={{ fontSize: "5rem", letterSpacing: "3px" }}
            className="fw-bold text-secondary"
          >
            Coming Soon
          </h1>
        </div>
        <a href="/privacy-policy">Privacy Policy</a>
      </div>
    </div>
  );
};
