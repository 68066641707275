import React from "react";
import logo from "../../assets/Jemmity_logo_230723.png";

export const PrivacyPolicyPage = () => {
  return (
    <div className="container mt-5 mb-5 pb-5">
      <a href="/">
        <img src={logo} alt="JEMMITY" style={{ width: "300px" }} />
      </a>
      <h2 className="text-center mb-4">Jemmity Privacy Policy</h2>

      <p>
        Jemmity "the App” provides drop shipping "the Service" to merchants who
        use Shopify to power their stores. This Privacy Policy describes how
        personal information is collected, used, and shared when you install or
        use the App in connection with your Shopify-supported store.
      </p>

      <h5 className="mt-4">Personal Information the App Collects</h5>

      <p>
        When you install the App, we are automatically able to access certain
        types of information from your Shopify account: view and create
        fulfillment orders, view customer information, view and create orders,
        view and modify inventory levels, view and add products, view and modify
        shipping information.
      </p>

      <p>
        Additionally, we collect the following types of personal information
        from you and/or your customers once you have installed the App:
        Information about you and others who may access the App on behalf of
        your store, such as your name, address, email address, phone number, and
        billing information; Information about your customers who purchase from
        your store, such as their IP address, web browser details, language
        locale, time zone, email, and default address.
      </p>

      <p>
        We collect personal information directly from the relevant individual,
        through your Shopify account. “Log files” track actions occurring on the
        Site, and collect data including your IP address, browser type, Internet
        service provider, referring/exit pages, and date/time stamps. “Web
        beacons,” “tags,” and “pixels” are electronic files used to record
        information about how you browse the Site.
      </p>
      <p>
        We utilize analytics tools to help us identify patterns and trends of
        use for App users, as well as measure and report statistics, to improve
        the overall App experience for the merchant. These tools include Google
        Analytics and Microsoft Clarity that collect information like IP
        address, device or browser information, and site activity (click rates,
        dead links, etc.). To learn more about these tools, please visit the
        following links:{" "}
        <a
          href="https://policies.google.com/technologies/partner-sites"
          target="_blank"
          rel="noreferrer"
        >
          Google Analytics
        </a>
        ,{" "}
        <a
          href="https://learn.microsoft.com/en-us/clarity/faq"
          target="_blank"
          rel="noreferrer"
        >
          Microsoft Clarity
        </a>
        .
      </p>

      <h5 className="mt-4">How Do We Use Your Personal Information?</h5>

      <p>
        We use the personal information we collect from you and your customers
        in order to provide the Service and to operate the App. Additionally, we
        use this personal information to: Communicate with you; Optimize or
        improve the App; and Provide you with information or advertising
        relating to our products or services.
      </p>

      <h5 className="mt-4">Sharing Your Personal Information</h5>

      <p>
        We may share your Personal Information to comply with applicable laws
        and regulations, to respond to a subpoena, search warrant or other
        lawful request for information we receive, or to otherwise protect our
        rights.
      </p>

      {/*<p>
        [[INCLUDE IF USING REMARKETING OR TARGETED ADVERTISING]] Behavioural
        Advertising As described above, we use your Personal Information to
        provide you with targeted advertisements or marketing communications we
        believe may be of interest to you. For more information about how
        targeted advertising works, you can visit the Network Advertising
        Initiative’s (“NAI”) educational page at{" "}
        <a href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work">
          http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work
        </a>
        .
      </p>

      <p>
        You can opt out of targeted advertising by: [[INCLUDE OPT-OUT LINKS FROM
        WHICHEVER SERVICES BEING USED. COMMON LINKS INCLUDE: FACEBOOK; GOOGLE;
        BING]] Additionally, you can opt out of some of these services by
        visiting the Digital Advertising Alliance’s opt-out portal at:{" "}
        <a href="http://optout.aboutads.info/">http://optout.aboutads.info/</a>.
      </p>

      <p>
        [[INCLUDE IF LOCATED IN OR IF YOU SERVICE MERCHANTS/CUSTOMERS IN
        EUROPE]] Your Rights If you are a European resident, you have the right
        to access personal information we hold about you and to ask that your
        personal information be corrected, updated, or deleted. If you would
        like to exercise this right, please contact us through the contact
        information below.
      </p>

      <p>
        Additionally, if you are a European resident we note that we are
        processing your information in order to fulfill contracts we might have
        with you (for example if you make an order through the Site), or
        otherwise to pursue our legitimate business interests listed above.
        Additionally, please note that your information will be transferred
        outside of Europe, including to Canada and the United States.
      </p>

        <p>[[ADD IN INFORMATION ABOUT EU-U.S. PRIVACY SHIELD IF RELEVANT]]</p>*/}

      <h5 className="mt-4">Data Retention</h5>
      <p>
        When you place an order through the Site, we will maintain your Order
        Information for our records for a minimum period of six (6) years from
        the end of the last tax year for tax purposes for the Canada Revenue
        Agency as per Canadian law, after which time you may request us to
        delete this information.
      </p>

      <h5 className="mt-4">Changes</h5>
      <p>
        We may update this privacy policy from time to time in order to
        reflect, for example, changes to our practices or for other operational,
        legal or regulatory reasons.
      </p>

      <h5 className="mt-4">Contact Us</h5>
      <p>
        For more information about our privacy practices, if you have
        questions, or if you would like to make a complaint, please contact us
        by e-mail at{" "}
        <a href="mailto:support@jemmity.com">support@jemmity.com</a>.
      </p>

      <p className="fst-italic text-secondary mt-5">Last revised September 2023</p>
    </div>
  );
};
