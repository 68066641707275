// import logo from "./logo.svg";
// import "./App.css";

// import { Route, Routes, useRoutes } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { HomePage } from "./pages/home/home.component";
import { PrivacyPolicyPage } from "./pages/privacy-policy/privacy-policy.component";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicyPage />} />
        </Routes>
      </Router>
    </div>
  );

  // let element = useRoutes([
  //   {
  //     path: "/",
  //     element: <HomePage />,
  //     // children: [
  //     //   {
  //     //     path: "messages",
  //     //     element: <DashboardMessages />,
  //     //   },
  //     //   { path: "tasks", element: <DashboardTasks /> },
  //     // ],
  //   },
  //   { path: "privacy-policy", element: <PrivacyPolicyPage /> },
  // ]);

  // return element;
}

export default App;
